<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <h4>Add User</h4>
            </b-col>
          </b-row>

          <b-row class="h-100" v-if="state !== 'show'" align-h="center">
            <b-spinner label="Loading..."></b-spinner>
          </b-row>

          <b-form v-if="state === 'show'" @submit.stop.prevent="submit">
            <b-row>
              <b-col cols="4">

                <b-form-group id="name-input-group" label="Name" label-for="name">
                  <b-form-input
                      id="name"
                      name="name"
                      v-model="$v.userData.name.$model"
                      :state="validateState('name')"

                      aria-describedby="name-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="name-live-feedback"
                  >{{ this.invalidMesg('name') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!--                <label>Name <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.name"-->
                <!--                              @blur="userData.name.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.name" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.name.required">This is a required field</p>
                                </div>-->

              </b-col>
              <b-col cols="4">
                <b-form-group id="surname-input-group" label="Surname" label-for="surname">
                  <b-form-input
                      id="surname"
                      name="surname"
                      v-model="$v.userData.surname.$model"
                      @blur="userData.surname.$touch()"
                      :state="validateState('surname')"
                      aria-describedby="surname-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="surname-live-feedback"
                  >{{ this.invalidMesg('surname') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Surname <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.surname"-->
                <!--                              @blur="userData.surname.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.surname" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.surname.required">This is a required field</p>
                                </div>-->


              </b-col>
              <b-col cols="4">
                <b-form-group id="roleID-input-group" label="Role ID" label-for="roleID">
                  <b-form-select id="roleID"
                                 name="roleID"
                                 v-model="$v.userData.roleID.$model"
                                 :state="validateState('roleID')"
                                 aria-describedby="roleID-live-feedback">
                    <b-form-select-option v-for="(item, index) in role" :key="index" :value="item.id">{{ item.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback
                      id="roleID-live-feedback"
                  >{{ this.invalidMesg('roleID') }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <!--                <label>Role</label>-->
                <!--                <b-form-select v-model="userData.roleID" >-->
                <!--                  <b-form-select-option v-for="(item, index) in role" :key="index" :value="item.id">{{item.name}}</b-form-select-option>-->
                <!--                </b-form-select>-->
              </b-col>

            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group id="contactNumber-input-group" label="Contact Number" label-for="contactNumber">
                  <b-form-input
                      id="contactNumber"
                      name="contactNumber"
                      v-model="$v.userData.contactNumber.$model"
                      @blur="userData.contactNumber.$touch()"
                      :state="validateState('contactNumber')"
                      aria-describedby="contactNumber-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="contactNumber-live-feedback"
                  >{{ this.invalidMesg('contactNumber') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Contact Number</label>-->
                <!--                <b-form-input v-model="userData.contactNumber"-->
                <!--                              @blur="userData.contactNumber.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.contactNumber" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.contactNumber.minLength || !$v.userData.contactNumber.maxLength || !$v.userData.contactNumber.numeric">Please enter a valid number</p>
                                </div>-->
              </b-col>
              <b-col cols="4">
                <b-form-group id="email-input-group" label="Email Address" label-for="email">
                  <b-form-input
                      id="email"
                      name="email"
                      v-model="$v.userData.email.$model"
                      :state="validateState('email')"
                      aria-describedby="email-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="email-live-feedback"
                  >{{ this.invalidMesg('email') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Email <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.email"-->
                <!--                              @blur="userData.email.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.email" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.email.required">This is a required field</p>
                                  <p v-if="!userData.email.email">Please enter a valid email</p>
                                </div>-->
              </b-col>
              <b-col cols="4">
                <b-form-group id="password-input-group" label="Password" label-for="password">
                  <b-form-input
                      id="password"
                      name="password"
                      v-model="$v.userData.password.$model"
                      :state="validateState('password')"
                      aria-describedby="password-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="password-live-feedback"
                  >{{ this.invalidMesg('password') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Password <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.password"-->
                <!--                              @blur="userData.password.$touch()"></b-form-input>-->
                <!--                <div v-if="$v.userData.password" class="text-red font-weight-400 text-left">
                                  <p v-if="!$v.userData.password.required">This is a required field</p>
                                  <p v-if="!$v.userData.password">Please enter a valid email</p>
                                </div>-->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="4">
                <b-form-group id="vendor-input-group" label="Vendor" label-for="vendor">
                  <b-form-select id="vendor"
                                 name="vendor"
                                 v-model="$v.userData.vendor.$model"
                                 :state="validateState('vendor')"
                                 aria-describedby="vendor-live-feedback">
                    <b-form-select-option v-for="(item, index) in companies" :key="index" :value="item.name">{{ item.name }}
                    </b-form-select-option>
                  </b-form-select>
                  <b-form-invalid-feedback
                      id="vendor-live-feedback"
                  >{{ this.invalidMesg('vendor') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Contact Number</label>-->
                <!--                <b-form-input v-model="userData.contactNumber"-->
                <!--                              @blur="userData.contactNumber.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.contactNumber" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.contactNumber.minLength || !$v.userData.contactNumber.maxLength || !$v.userData.contactNumber.numeric">Please enter a valid number</p>
                                </div>-->
              </b-col>
              <b-col cols="4">
                <b-form-group id="email-input-group" label="Land Line" label-for="landline">
                  <b-form-input
                      id="landline"
                      name="landline"
                      v-model="$v.userData.landline.$model"
                      :state="validateState('landline')"
                      aria-describedby="landline-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="landline-live-feedback"
                  >{{ this.invalidMesg('landline') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Email <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.email"-->
                <!--                              @blur="userData.email.$touch()"></b-form-input>-->
                <!--                <div v-if="userData.email" class="text-red font-weight-400 text-left">
                                  <p v-if="!userData.email.required">This is a required field</p>
                                  <p v-if="!userData.email.email">Please enter a valid email</p>
                                </div>-->
              </b-col>
              <b-col cols="4">
                <b-form-group id="passwordConfirm-input-group" label="Confirm Password" label-for="passwordConfirm">
                  <b-form-input
                      id="passwordConfirm"
                      name="passwordConfirm"
                      v-model="$v.userData.passwordConfirm.$model"
                      :state="validateState('passwordConfirm')"
                      aria-describedby="passwordConfirm-live-feedback"
                      type="password"
                  ></b-form-input>

                  <b-form-invalid-feedback
                      id="passwordConfirm-live-feedback"
                  >{{ this.invalidMesg('passwordConfirm') }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <!--                <label>Password <span class="text-red">*</span></label>-->
                <!--                <b-form-input v-model="userData.password"-->
                <!--                              @blur="userData.password.$touch()"></b-form-input>-->
                <!--                <div v-if="$v.userData.password" class="text-red font-weight-400 text-left">
                                  <p v-if="!$v.userData.password.required">This is a required field</p>
                                  <p v-if="!$v.userData.password">Please enter a valid email</p>
                                </div>-->
              </b-col>
            </b-row>
            <b-alert variant="danger" v-if="showError">
              {{ errorMessage }}
            </b-alert>

            <hr class="mx-3">
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <div>
                    <b-button variant="outline-red" squared @click="goBackToSearch" class="ml-2">Cancel</b-button>
                  </div>
                  <div>
                    <b-button variant="primary" squared @click="submit()" class="ml-2" :disabled="$v.userData.$invalid">
                      Save
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-form>

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.primary {
  background-color: #ffcb14;
  color: #222222;
}

.primary:hover {
  color: #fff;
}

.page-item.active .page-link {
  z-index: 3;
  color: #222222;
  background-color: #ffcb14;
  border-color: #ffcb14;
}
</style>
<script>
import {mapMutations} from "vuex";
import {required, email, minLength, maxLength, numeric, sameAs} from "vuelidate/lib/validators";
import api from "../../../api";
import {validationMixin} from "vuelidate";

export default {
  mixins: [validationMixin],
  name: "createUser",
  data: () => ({
    state: 'show',
    showError: false,
    errorMessage: "",
    userData: {
      name: null,
      surname: null,
      email: null,
      password: null,
      passwordConfirm:null,
      contactNumber: null,
      vendor:null,
      landline:null,
      roleID: null,
      favoriteIds: null // This enables favorites assigning on user creation in future.

    },
    role: [],
    companies:[],
    testrolesave: null,
  }),

  created() {

    this.setBreadcrumb([
      {
        text: 'User'
      },
      {
        text: 'Add New User'
      },
    ]);

    const s = (data) => {
      this.role = data;

    };

    const e = (msg) => {
      console.log(msg);
      this.makeToast(msg);
    };
    api.getRoles(s, e);
    this.getCompanies();

  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),

    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false; // stop here if form is invalid
      } else {
        const s = () => {
          this.$router.push({path: '/admin/ManageUser/SearchUser'})

        };

        const e = (msg) => {
          console.log("Show Error")

          console.log("Error message", msg);
          this.makeToast(msg)

        };

        // console.log("this.userData", this.userData);
        api.CreateUser(this.userData, s, e);
      }
    },

    goBackToSearch() {
      this.$router.back()
    },

    makeToast(message) {
      this.$bvToast.toast(`${message}`, {
        title: 'Something went wrong.',
        autoHideDelay: 5000,
        appendToast: false
      })
    },

    validateState(name) {
      // console.log(name)
      // console.log(this.$v.user[name])
      const {$dirty, $error} = this.$v.userData[name];
      return $dirty ? !$error : null;
    },
    invalidMesg(name) {
      //Returns appropriate error message for end user.
      switch (name) {
        case "name": {
          return "This is a required field."
        }
        case "surname": {
          return "This is a required field."
        }
        case "email": {
          return "This is a required field and must be a valid Email address."
        }
        case "contactNumber": {
          return "This is a required field and must be 10 digits long.."
        }
        case "landline": {
          return "This is a required field and must be 10 digits long.."
        }
        case "vendor": {
          return "This is a required field."
        }

        case "roleID": {
          return "This is a required field."
        }
        case "password":{return "This is a required field and must be at least 9 characters, must contain an Upper case and lower case letter and at lease one special character."}
        case "passwordConfirm":{return "Passwords no not Match."}
      }
    },
    getCompanies(){
      api.GetVendors(s=>{this.companies=s
        console.log(s)},console.error)
    }

  },
  computed: {},
  validations: {
    userData: {
      name: {required},
      surname: {required},
      email: {email, required},
      roleID: {required},
      contactNumber: {minLength: minLength(10), maxLength: maxLength(10), numeric},
      landline: {minLength: minLength(10), maxLength: maxLength(10), numeric},
      vendor: {required},
      password: {
        required,
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value)
          const containsLowercase = /[a-z]/.test(value)
          const containsNumber = /[0-9]/.test(value)
          const containsSpecial = /[#?!@$%^&*-]/.test(value)
          return containsUppercase && containsLowercase && containsNumber && containsSpecial
        },

        minLength: minLength(9),
        maxLength: maxLength(19)
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
    },
  },
}
</script>

<style scoped>

</style>